import React from 'react';
import {PassportsPage} from "./pages/PassportsPage";
import LandingLayout from "../../components/LandingLayout/LandingLayout";

export const passportsRoutes = [
    {
        element: <LandingLayout/>,
        children: [
            {
                path: '',
                element: <PassportsPage/>
            },
        ]
    },
]
