import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {App, Spin} from "antd";
import styles from './PassportsPage.module.css'
import {getAllPassports} from "../../../../store/slices/passportsSlice";
import {usePassports} from "../../../../hooks/use-passports";
import PassportsTable from "../../components/PassportsTable/PassportsTable";

export const initialPassportsTableColumns = [
    {
        key: 'uid',
        name: 'Номер паспорта',
    },
    {
        key: 'date',
        name: 'Дата паспорта',
    },
    {
        key: 'request_uid',
        name: 'Номер заявки',
    },
    {
        key: 'request_date',
        name: 'Дата заявки',
    },
    {
        key: 'short_name',
        name: 'Название',
    },
    {
        key: 'tags',
        name: 'Теги',
    },
    {
        key: 'request_goal',
        name: 'Цель',
    },
    {
        key: 'request_result',
        name: 'Результат',
    },
    {
        key: 'request_description',
        name: 'Описание',
    },
    {
        key: 'request_criteria',
        name: 'Критерии оценивания',
    },
    {
        key: 'status',
        name: 'Статус',
    },
    {
        key: 'course',
        name: 'Курсы',
    },
    {
        key: 'customer_company_name',
        name: 'Заказчик',
    },
    {
        key: 'customer_name',
        name: 'Представитель заказчика',
    },
    {
        key: 'available_seats_number',
        name: 'Количество мест',
    },
]

export function PassportsPage() {
    const dispatch = useDispatch();
    const [passportsTable, setPassportsTable] = useState([])

    const passports = usePassports()

    useEffect(() => {
        dispatch(getAllPassports({period_id: 9}))
    }, []);

    useEffect(() => {
        setPassportsTable(passports.passports.map(passport => ({
            id: passport.id,
            short_name: passport.short_name || passport.request.name,
            is_name_from_request: !!!passport.short_name,
            diploma_name: passport.diploma_name,
            team_count: passport.team_count,
            students_count: passport.students_count,
            request_id: passport.request.id,
            request_goal: passport.request.goal,
            request_result: passport.request.result,
            request_description: passport.request.description,
            request_criteria: passport.request.criteria,
            course: passport.course,
            period: passport.request.period_id,
            customer_id: passport.request.customer_user.id,
            customer_name: (passport.request.customer_user.last_name || "") + " " + (passport.request.customer_user.first_name || "") + " " + (passport.request.customer_user.middle_name || ""),
            customer_company_name: passport.request.customer_user.customer_company.name,
            tags: passport.request.tags
        })))
    }, [passports])

    return (
        <div className={styles.page}>
            <h1 className={styles.title}>Проекты осеннего семестра 2024-2025</h1>
            {
                passports.isLoading ?
                    <Spin/> :
                    <PassportsTable
                        defaultPassports={passports}
                        passports={passportsTable}
                    />
            }
        </div>
    )
}
