import {HOST} from './host';

const GET_TAGS = `${HOST}/tag/all`;
const GET_PASSPORTS = `${HOST}/passport/all/for-students`;

const API = {
    GET_PASSPORTS,
    GET_TAGS,
};

export default API;
