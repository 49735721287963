import React, {useEffect, useRef, useState} from 'react';
import {Button, Input, Space, Table, Tag, Typography} from "antd";
import {useDispatch} from "react-redux";
import styles from "./PassportsTable.module.css"
import {SearchOutlined} from "@ant-design/icons";
import {getAllTags} from "../../../../store/slices/tagsSlice";
import {useTags} from "../../../../hooks/use-tags";
import parse from 'html-react-parser';

const {Paragraph} = Typography;

const {Column, ColumnGroup} = Table;
const {TextArea} = Input;

export default function PassportsTable(props) {
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const tags = useTags()

    useEffect(() => {
        dispatch(getAllTags())
    }, []);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm({
            closeDropdown: false,
        });
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Поиск`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Найти
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        }
    });

    return (
        <div className={styles.tableContainer}>
            <Table
                className={styles.table}
                bordered={true}
                dataSource={props.passports}
                size="small"
                scroll={{
                    y: "calc(100vh - 220px)",
                    x: "calc(100vw - 80px)"
                }}
                pagination={{
                    pageSize: 30,
                    showSizeChanger: false
                }}
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.darkRow}
            >
                <Column
                    title="Название"
                    width={250}
                    dataIndex="short_name"
                    key="short_name"
                    render={(value, record) => {
                        return <p>
                            {value}
                        </p>
                    }}
                    sorter={(a, b) => a.short_name.localeCompare(b.short_name)}
                    {...getColumnSearchProps("short_name")}
                />

                <Column
                    title="Теги"
                    width={200}
                    dataIndex="tags"
                    key="tags"
                    render={(value, record) => {
                        return <div>
                            {value.map(tag => {
                                return <Tag color={tag.color}>
                                    {tag.text}
                                </Tag>
                            })}
                        </div>
                    }}
                    filters={!tags.isLoading ? tags.tags.map(tag => ({
                        text: tag.text,
                        value: tag.id
                    })) : []}
                    onFilter={(value, record) => record.tags.find(t => t.id === value)}
                />

                <Column
                    title="Курсы"
                    width={90}
                    dataIndex="course"
                    key="course"
                    render={(value, record) => {
                        return <>
                            {
                                value.map(course => {
                                    return <Tag>{course.name}</Tag>
                                })
                            }

                        </>
                    }}
                    filters={[{text: "1 курс", value: 1}, {text: "2 курс", value: 2}, {
                        text: "3 курс",
                        value: 3
                    }, {text: "4 курс", value: 4},]}
                    onFilter={(value, record) => record.course.filter(course => course.number === value).length !== 0}
                />

                <Column
                    title="Цель"
                    width={500}
                    dataIndex="request_goal"
                    key="request_goal"
                    render={(value, record) => {
                        const parser = new DOMParser();
                        return <Paragraph
                            ellipsis={{rows: 10, expandable: true, symbol: 'more'}}
                        >
                            {parse(value)}
                        </Paragraph>
                    }}
                    {...getColumnSearchProps("request_goal")}
                />

                <Column
                    title="Результат"
                    width={500}
                    dataIndex="request_result"
                    key="request_result"
                    render={(value, record) => {
                        return <Paragraph
                            ellipsis={{rows: 10, expandable: true, symbol: 'more'}}
                        >
                            {parse(value)}
                        </Paragraph>
                    }}
                    {...getColumnSearchProps("request_result")}
                />

                <Column
                    title="Описание"
                    width={500}
                    dataIndex="request_description"
                    key="request_description"
                    render={(value, record) => {
                        return <Paragraph
                            ellipsis={{rows: 10, expandable: true, symbol: 'more'}}
                        >
                            {parse(value)}
                        </Paragraph>
                    }}
                    {...getColumnSearchProps("request_description")}
                />

                <Column
                    title="Критерии оценивания"
                    width={500}
                    dataIndex="request_criteria"
                    key="request_criteria"
                    render={(value, record) => {
                        return <Paragraph
                            ellipsis={{rows: 10, expandable: true, symbol: 'more'}}
                        >
                            {parse(value)}
                        </Paragraph>
                    }}
                    {...getColumnSearchProps("request_criteria")}
                />

                <Column
                    title="Заказчик"
                    width={200}
                    dataIndex="customer_company_name"
                    key="customer_company_name"
                    {...getColumnSearchProps("customer_company_name")}
                />

                <Column
                    title="Представитель заказчика"
                    width={140}
                    dataIndex="customer_name"
                    key="customer_name"
                    {...getColumnSearchProps("customer_name")}
                />

                <Column
                    title="Максимальное количество команд"
                    width={130}
                    dataIndex="team_count"
                    key="team_count"
                    sorter={(a, b) => a.team_count - b.team_count}
                />

                <Column
                    title="Количество студентов в команде"
                    width={120}
                    dataIndex="students_count"
                    key="students_count"
                    sorter={(a, b) => a.students_count - b.students_count}
                />
            </Table>
        </div>
    );
};
