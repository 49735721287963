import {configureStore} from '@reduxjs/toolkit';
import passportsReducer from "./slices/passportsSlice";
import passportReducer from "./slices/passportSlice";
import tagsReducer from "./slices/tagsSlice";

export const store = configureStore({
    reducer: {
        passports: passportsReducer,
        passport: passportReducer,
        tags: tagsReducer
    },
});
